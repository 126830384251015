.confirmRow {
  width:50%;
}

.closeRow {
  width:50%;
}

.deleteButtonTransparent {
  font-size: 30px !important;
  padding: 0;
  margin: 0;
  border: none !important;
  background-color: transparent !important;
  color: black !important;
}

.deleteButton {
  font-size: 30px !important;
  padding: 0;
  margin: 0;
  border: none !important;
  background-color: transparent !important;
  color: $teal-bright !important;
}

.confirmButton {
  border-radius: 10px;
  font-size: 2rem;
  border: none !important;
  background-color: black;
  color: $red !important;
  width: 100%;
  margin: auto;
}

.confirmButton:hover, .confirmButton:focus {
  background-color: $red !important;
  border-color: $red !important;
  color: black !important;
}

.closeButton {
  border-radius: 10px;
  font-size: 2rem;
  border: none !important;
  background-color: black;
  color: $teal;
  width: 100%;
  margin: auto;
}

.closeButton:hover, .closeButton:focus {
  background-color: $teal !important;
  border-color: $red !important;
  color: black !important;
  animation-name: borderColorCloseButton;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes borderColorCloseButton {
  0% {
    background-color: $red !important;
    // border-color: rgba($teal, 0);
    color: $red;
  }
  25% {
    background-color: $red !important;
    border-color: $teal;
    color: $teal;
  }
  50% {
    background-color: $red !important;
    border-color: $red;
    color: $red;
  }
  75% {
    background-color: $red !important;
    border-color: $teal;
    color: $teal;
  }
  100% {
    background-color: $red !important;
    border-color: $red;
    color: $red;
  }
}

.disabledBtn {
  width: 100%;
  background-color: black !important;
  color: grey !important;
  border-color: grey !important;
}

.saveButton {
  width: 100%;
  border: 5px solid black;
  padding: 3px;
  box-shadow: none;
  color: $white;
  background-color: black !important;
  animation-name: borderColorSaveButton;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.saveButton:hover, .saveButton:focus {
  background-color: black;
}

.saveTaskButtonTransparent {
  font-size: 30px !important;
  margin: 0;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none;
}

.saveTaskButtonTransparentActive {
  animation-name: borderColorSaveTaskButton;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.saveTaskButtonTransparentInactive {

}

/* Standard syntax */
@keyframes borderColorSaveTaskButton {
  0% {
    border-color: $red;
    color: $red;
  }
  25% {
    border-color: black;
    color: black;
  }
  50% {
    border-color: $red;
    color: $red;
  }
  75% {
    border-color: black;
    color: black;
  }
  100% {
    border-color: $red;
    color: $red;
  }
}

.saveTaskButtonInactive {
  color: grey;
  background-color: black;
}

.saveTaskButtonInactive:hover, .saveTaskButtonInactive:focus {

}

.saveTaskButtonActive {
  animation-name: borderColorSaveButton;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.saveTaskButtonActive:hover, .saveTaskButtonActive:focus {
  border-color: $red !important;
  color: $red !important;
}

.saveTaskButton {
  width: 100%;
  font-size: 30px !important;
  padding: 0;
  margin: 0;
  border: none !important;
  // background-color: black !important;
  box-shadow: none;
}

/* Standard syntax */
@keyframes borderColorSaveButton {
  0% {
    border-color: $red;
    color: $red;
  }
  25% {
    border-color: $teal;
    color: $teal;
  }
  50% {
    border-color: $red;
    color: $red;
  }
  75% {
    border-color: $teal;
    color: $teal;
  }
  100% {
    border-color: $red;
    color: $red;
  }
}

.inputLabel {
  font-family: monospace;
  font-size: 20px;
  padding: 0.5rem 0.45rem;
  color: #DDDDDD !important;
  background-color: black !important;
}

.taskBody {
  display: inline-block;
  background-color: $purple;
  border: 1px solid black;
  border-radius: 5px;
  width: 100%;
  padding: 0.5rem 0.45rem;
}

.taskBody:hover {
  border-color: $teal;
}

.inputMinimised {
  width: 100%;
  font-family: monospace;
  font-weight: bold;
  padding: 0.5rem 0.45rem;
  color: #DDDDDD !important;
  background-color: $purple !important;
}

.inputMaximised {
  width: 100%;
  font-family: monospace;
  padding: 0.5rem 0.45rem;
  color: #DDDDDD !important;
  line-height: 2rem;
}

.inputMaximised:hover,
.inputMaximised:focus,
.inputMaximised:hover {
  border-color: $teal !important;
}

.newCardInput {
  font-size: 20px !important;
  padding: 0.5rem 0.45rem;
  color: $white !important;
  background-color: black !important;
  width: 100%;
  margin: auto;
  height: 4rem !important;
}

.input {
  border-radius: 5px !important;
  font-size: 20px !important;
  padding: 0.5rem 0.45rem;
  color: $white !important;
  background-color: black !important;
  width: 100%;
  margin: auto;
  height: 4rem !important;
}

.inputActive:hover, .inputActive:focus {
  animation-name: borderColorPaletteActive;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

/* Standard syntax */
@keyframes borderColorPaletteActive {
  0% {
    border: 1px solid $teal !important;
  }
  25% {
    border: 1px solid $red !important;
  }
  50% {
    border: 1px solid $red !important;
  }
  75% {
    border: 1px solid $teal !important;
  }
  100% {
    border: 1px solid $teal !important;
  }
}

.inputEdited {
  border-color: $red !important;
}

.inputEdited:hover, .inputEdited:focus {
  border-color: $warning-color !important;
}

.inputSaving {
  border-color: $purple !important;
}

.inputSaved {
  animation-name: borderColorPaletteSaved;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-direction: alternate;
}

/* Standard syntax */
@keyframes borderColorPaletteSaved {
  0% {
    border-color: $green;
  }
  25% {
    border-color: $green;
  }
  50% {
    border-color: $teal;
  }
  75% {
    border-color: $teal-bright;
  }
  100% {
    border-color: $teal-bright;
  }
}

@media (min-width: 400px) {

  .input {
    padding: 0.2rem 0.4rem !important;
    height: 3rem !important;
    font-size: 25px !important;
  }
}

@media (min-width: 600px) {

  .input {
    padding: 0.4rem 0.6rem !important;
    height: 3rem !important;
    font-size: 25px !important;
  }
}
