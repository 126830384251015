@import "./antdstyles.scss";
@import "../components/Boards/Boards.module.scss";
@import "../components/Inputs/Inputs.module.scss";
@import "../components/Cards/Card.module.scss";


.container {
  min-height: 90vh;
  // padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto !important;
}

.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputBig {
  color: $white;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin-top: 3rem;
}

.taskRow {
  margin-bottom: 0.5rem;
  padding: 10px;
  border-radius: $border-radius-base;
}

.transparentBg {
  background-color: transparent !important;
}

.blackBg {
  background-color: #121212 !important;
  color: #333333;
}

.greyBg {
  background-color: #222222 !important;
  color: black;
}

.searchRow {
  margin-top: 10px;
}

.searchCard {
  font-size: 1rem;
  width: 100%;
  margin: auto;
  margin-top: 2rem;
  color: #DDDDDD !important;
  background-color: black !important;
  border: 1px solid;
  border-color: grey;
}

.searchCard:hover,
.searchCard:focus,
.searchCard:active {
  color: $purple;
  border-color: $purple;
}

.dateSelector {
  background-color: #343434 !important;
  color: rgba(221,221,221) !important;
}

.boardCard {
  margin-top: 15px !important;
  border: solid 10px $teal !important;
  border-radius: 10px !important;
  // padding: none !important;
}

.card {
  position: relative;
  margin: 1rem;
  flex-basis: 45%;
  width: 100%;
  text-align: left;
  color: #DDDDDD !important;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: $border-radius-base;
  transition: color 0.15s ease, border-color 0.15s ease;
}

.card:hover,
.card:focus,
.card:active {
  color: $teal;
  border-color: $teal;
}

.card h3 {
  margin: 0 0 1rem 0;
}

.card p {
  margin: 0;
  line-height: 1.5;
}

.title {
  color: #DDDDDD !important;
}

.logo {
  height: 1em;
}



.greenBtn {
  background-color: $teal !important;
  color: $white !important;
  font-weight: bold !important;
  border: 1px solid black !important;
}

.greenBtn:hover {
  background-color: $purple !important;
  color: $white !important;
}

.redBtn {
  background-color: $red !important;
  color: $white !important;
  font-weight: bold !important;
  border: 1px solid black !important;
}

.redBtn:hover {
  background-color: $purple !important;
  color: $white !important;
}

.tealBtn {
  background-color: $teal !important;
  color: $white !important;
  font-weight: bold !important;
  border: 1px solid black !important;
}

.tealBtn:hover {
  background-color: $teal-bright !important;
}

.tealCard {
  background-color: $teal !important;
}

.purpleCard {
  background-color: $purple !important;
}

.staticTaskText {
  width: 100%;
}

.inputBackground {
  transition: .6s ease;

}

.addCardForm {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid $white;
  margin-bottom: 15px;
  margin-top: 15px;
}

.inputSaving  {
  background-color: green;
  border-color: green;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
}

.inner {

}

.timestampActive {
  color: $teal-bright;
}

.timestampInactive {
  color: grey !important;
}

.navBar {
   min-height: 5vh;
}

.navigation {
  width: 100%;
  padding: none;
  margin: none;
}

.navigationBodyRow {
  background-color: red;
  z-index: 100;
  top:0;
  width: 100%;
}

.navigationBody {
  background-color: red;
  position: fixed;
  z-index: 100;
  top:0;
  width: 100%;
}

.navLink {
  width: fit-content;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.signinForm {
  position: relative;
  width: 80%;
  margin: auto;
  margin-bottom:10px;
}

.modalContent {
  width: 100%;
  margin: auto;
}

.newBoardForm {
  background-color: #424242;
}

.controlPanelRow {
  // height: 100%;
}

.controlPanelCol {
  // width: 100%;
}


.trunk {
  display: block;
  width: 60%;
  margin: auto;
  padding: 10px;
  background-color: black;
}

@media (min-width: 300px) {
  .trunk {
    width: 100%;
    margin: auto;
    padding: 10px;
  }
}

@media (min-width: 1000px) {
  .trunk {
    width: 80%;
    margin: auto;
    padding: 10px;
  }
}

@media (min-width: 1200px) {
  .trunk {
    // display: block;
    width: 60%;
    margin: auto;
    padding: 10px;
  }
}



.debugRow {
  // border: 3px solid;
  // border-color: $error-color;
}

.debugCol {
  // border: 3px solid;
  // border-color: blue;
}

.debugInput {
  // border: 3px solid;
  // border-color: blue;
}
