.deleteButtonBlack {
  font-size: 30px !important;
  padding: 0;
  margin: 0;
  border: none !important;
  background-color: transparent !important;
  color: black !important;
}

.activeCard {
  font-size: 30px !important;
  padding: 0;
  margin: 0;
  border: none !important;
  background-color: transparent !important;
}

.activeCardCold {
  color: $teal-bright !important;
}

.activeCardWarm {
  color: $warning-color !important;
}

.activeCardHot {
  color: $red !important;
}


.inactiveCard {
  font-size: 30px !important;
  padding: 0;
  margin: 0;
  border: none !important;
  background-color: transparent !important;
  color: grey !important;
}
