.controlPanelBtn {
  color: red !important;
}

.board {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  width: 100%;
  margin: auto;
  margin-top: 15px !important;
  padding: 0.5rem 0.45rem;
  background-color: black;
}

.board ul {
  background-color: black;
}

.board:hover,
.board:focus,
.board:active {
  color: $teal;
  border-color: $teal;
}

.boardName {
  font-family: monospace;
  font-size: 30px;
  padding: 0.5rem 0.45rem;
  color: #DDDDDD !important;
  background-color: black !important;
}

.favouriteBoard {
  background-color: $purple !important;
  color: $white !important;
}

.favouriteButtonTransparent {
  font-size: 30px !important;
  padding: 0;
  margin: 0;
  border: none !important;
  background-color: transparent !important;
  color: $teal-bright !important;
}

.favouriteButton {
  font-size: 30px !important;
  padding: 0;
  margin: 0;
  border: none !important;
  background-color: transparent !important;
  color: white !important;
}
